.exercise-actions-cell div {
    width: 136px;
    margin-left: 4px;
    margin-right: 4px;
}

.exercise-actions-cell .outline-btn {
    margin-top: 8px;
    margin-bottom: 2px;
}

.exercise-actions-cell .action-btn {
    margin-top: 2px;
    margin-bottom: 8px;
}

.expiration-cell {
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.time-to-expiry {
    padding: 2px 10px;
    margin: 4px auto 0px;
    color: #5de9d4;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
}

.light-mode .time-to-expiry {
    background-color: #e6e6e6;
}