.vaults {    
    max-width: 760px;
    margin: auto;    
    padding: 16px;
}

.vaults-title {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.55px;
    text-align: center;
}

.vaults-subtitle {
    font-size: 12px;
    letter-spacing: 0.29px;
    text-align: center;
    margin-bottom: 12px;
}

.vault-asset {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vault-asset .asset-icon {
    width: 50px;
    margin-right: 12px;
    border-radius: 50%;
}

.vault-asset-details {
    display: flex;
    flex-direction: column;
}

.vault-asset-name {
    font-size: 17.5px;
    font-weight: 600;
    text-align: left;
    color: #5de9d4;
}

.vault-asset-description {
    font-size: 12.5px;
    font-weight: 300;
    text-align: left;
}