.simple-manage-tab {
    padding: 24px;
}

.simple-manage-tab .page-title {
    margin-top: 0;
    font-size: 14px;
}

.simple-manage-tab .aco-table {
    width: 100%;
}

.simple-manage-tab .written-options-positions {
    margin-bottom: 40px;
}