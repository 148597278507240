.simple-write-step2 {
    display: flex;
    flex-direction: column;
    width: 460px;
    max-width: calc(100% - 40px);
    margin: auto;
}

.collateral-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #464646;
    margin: 20px auto;
    width: 100%;
    border-radius: 12px;
    padding: 12px
}

.collateral-label {
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.7px;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
}

.collateral-input input {
    border: none;
    width: 60px;
    text-align: center;
    background-color: transparent;
    color: white;
}

.max-and-balance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

.max-and-balance .max-btn {
    position: relative;
}

.max-and-balance .balance {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-align: center;
}

.chart-and-summary {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: auto;    
    width: 100%;
}

.chart-and-summary .summary-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: solid 1px #464646;
    border-radius: 12px;
    padding: 12px;
}

.summary-content {
    display: flex;
    flex-direction: row;
}

.summary-items {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.summary-wrapper .summary-title-wrapper {
    padding: 8px 8px 12px 8px;
    margin-bottom: 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 58px;
}

.light-mode .summary-wrapper .summary-title-wrapper {
    background-color: #e6e6e6;
}

.summary-wrapper .summary-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.7px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 80px;
    min-width: 80px;
}

.summary-wrapper .summary-separator {
    width: 1px;
    height: 42px;
    background-color: #80808080;
    margin-left: 8px;
    margin-right: 8px;
}


.summary-wrapper .summary-description {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-align: left;
    color: #5de9d4;
}

.summary-wrapper .summary-highlight {   
    background-color: #1a1a1a;
}

.light-mode .summary-wrapper .summary-highlight {
    background-color: #f2f2f2;
}

.summary-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    position: relative;
}

.summary-item.summary-highlight .summary-item-label {
    font-weight: 500;
}

.summary-item .summary-item-label {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
}

.summary-item .summary-item-value {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    text-align: left;
    color: #e6e6e6;
    white-space: nowrap;
    padding-left: 5px;
}

.light-mode .summary-item .summary-item-value {
    color: #262626;
}

.light-mode .summary-highlight .summary-item-value {
    color: #5de9d4;
}

@media (max-width: 400px) {
    .summary-item {
        flex-direction: column;
    }   
    
    .summary-item .summary-item-label {
        text-align: center;
    }
}