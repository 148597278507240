.simple-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.simple-dropdown-placeholder {
    color: #464646;
}

.simple-dropdown .dropdown-toggle.empty-select::after {
    color: #464646;
}

.dropdown-menu.show {
    max-height: calc(100vh - 40px);
    overflow: auto;
}