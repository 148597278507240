.pool-dashboard {
    max-width: 760px;
    margin: auto;
    padding: 16px;
}

.pool-info-tabs {
    padding: 16px;
    border-radius: 12px;
    border: solid 1px #464646;
    margin-top: 16px;
}

.pool-dashboard .page-title {
    position: relative;
}

.back-link {
    position: absolute;
    left: 4px;
}

.pool-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 991px) {
    .pool-title {
        max-width: calc(100vw - 125px);
        margin-left: auto;
    }
}