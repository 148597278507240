.orderbook-box {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-height: 100%;
    border: 1px solid #464646;
    border-radius: 12px;
}


.box-title-wrapper {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.35px;
    color: rgb(255, 255, 255);
    border-bottom: none;
}

.box-title {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    margin: 0px;
    padding: 0px 20px 0px 0px;
}

.orderbook-table {
    margin: 0px;
    min-height: 140px;
    padding: 5px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    overflow: hidden;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: right;
}

.orderbook-table-headers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
}

.orderbook-table-header-item {
    border-bottom: 1px solid rgb(115, 115, 115);
    border-top: none;
    color: rgb(185, 185, 185);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.2;
    padding: 0px 16px 5px 0px;
    text-transform: uppercase;
    white-space: nowrap;
}

.orderbook-table-body {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-height: 500px;
    overflow: auto;
}

.orderbook-table-body-content {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: fit-content;
    position: relative;
    z-index: 1;
}

.orderbook-table-sell,
.orderbook-table-buy {
    display: flex;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
}

.orderbook-table-sell {    
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.orderbook-table-buy {    
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.orderbook-table-buy-row,
.orderbook-table-sell-row,
.orderbook-table-spread-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.orderbook-table-buy-row,
.orderbook-table-sell-row {
    cursor: pointer;
}

.orderbook-table-spread-row {
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    width: auto;
    z-index: 12;
}

.orderbook-table-spread-item {
    border-bottom: 1px solid rgb(115, 115, 115);
    border-top: 1px solid rgb(115, 115, 115);
    font-feature-settings: "tnum" 0;
    font-weight: normal;
    line-height: 1.2;
    padding: 5px 16px 5px 0px;
    color: rgb(185, 185, 185);
    font-size: 12px;
    text-transform: uppercase;
}

.orderbook-table-spread-value {
    border-bottom: 1px solid rgb(115, 115, 115);
    border-top: 1px solid rgb(115, 115, 115);
    color: rgb(255, 255, 255);
    font-feature-settings: "tnum" 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.2;
    padding: 5px 16px 5px 0px;
}

.orderbook-size-col,
.orderbook-price-col {
    color: rgb(255, 255, 255);
    font-feature-settings: "tnum";
    font-size: 13px;
    font-weight: normal;
    line-height: 1.2;
    padding: 5px 16px 5px 0px;
}

.orderbook-size-col {
    color: rgb(222, 222, 222);
}

.orderbook-table-buy-row .orderbook-price-col {
    color: #58fb0c;
}

.orderbook-table-sell-row .orderbook-price-col {
    color: #ff0000;
}