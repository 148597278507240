.simple-write-tab {
    padding: 0px 10px 20px;
}

.write-option-group {
    margin-top: 20px;
    margin-bottom: 20px;
}

.earn-title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #e6e6e6;
    margin-top: 12px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.simple-write-tab .aco-table {
    width: 100%;
}


.simple-write-tab .asset-icon {
    width: 34px;
    margin-right: 8px;
    border-radius: 50%;
}

.earn-title svg {
    align-self: flex-start;
    margin-left: 4px;
    color: #5de9d4;
}