.written-options-positions .page-title {
    margin-top: 20px;
}

.position-actions div{
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.55px;
    color: #656565;
    text-decoration: underline;
}

.position-actions div.clickable {
    color: #139bf2;
}

.grid-btn {
    padding-right: 0px;
    padding-left: 0px;
    width: 150px;
}