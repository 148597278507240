.otc-trade-tab {
    padding: 20px 10px;
    max-width: 760px;
    width: 100%;
    margin: auto;
    text-align: center;
}

.trade-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.9px;
    text-align: center;
    margin-top: 15px;
}

.light-mode .trade-title {
    color: #262626;
}

.trade-subtitle {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.35px;
    text-align: center;
    margin-bottom: 25px;
    color: #b2b2b2;
}

.light-mode .trade-subtitle {
    color: #666666;    
}

.otc-trade-tab .inputs-wrapper {
    display: flex;
    justify-content: space-between;
}

.otc-trade-tab .input-label {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    text-align: center;
}

.otc-trade-tab .input-field input {
    font-size: 16px;
    width: 100%;
    text-align: center;
}

.otc-trade-tab .input-field input::placeholder {
    color: #808080;
    opacity: 1;
}

.otc-trade-tab select {
    width: 100%;
    height: 100%;
    background: transparent;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.8px;
    border: none;
}

.otc-trade-tab .input-column {
    max-width: unset;
    margin: 0 10px;
    text-align: center;
}

.otc-trade-tab .input-column .input-field {
    width: unset;
    min-width: 115px;
}

.otc-trade-tab .input-column.strike-column,
.otc-trade-tab .input-column.expiration-column,
.otc-trade-tab .input-column.underlying-column {
    width: 175px;
    min-width: 175px;
    max-width: 175px;
}

@media (max-width: 768px) {
    .otc-trade-tab .inputs-wrapper {
        flex-wrap: wrap;
    }

    .otc-trade-tab .inputs-wrapper .input-column,
    .otc-trade-tab .inputs-wrapper .input-column.strike-column,
    .otc-trade-tab .inputs-wrapper .input-column.expiration-column  {
        width: calc(50% - 20px);
        min-width: unset;
        max-width: unset;
    }
}

.otc-steps-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.otc-steps-indicator {
    margin-top: 20px;
}

.otc-steps-indicator div {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 6px;
}

.otc-steps-indicator div.active-step {
    background-color: #5de9d4
}

.selected-option-label {
    font-weight: bold;
}