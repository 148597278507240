.trade-options-list {
    overflow: auto;
}

.trade-options-list .aco-table {
    width: calc(100% - 80px);
    min-width: 960px;
    overflow-x: auto;
    border: none;
}

.trade-options-list .aco-table td {
    border-right: none;
}

.trade-options-list .aco-table thead:first-of-type .option-expiry-title {
    margin-top: 0px;
}

.trade-options-list .aco-table td,
.trade-options-list .aco-table th {
    padding: 0 6px;
}

.option-expiry-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 32px;
    font-size: 14px;
}

.option-expiry-title > div {
    margin-left: 10px;
    margin-right: 10px;
    flex: 1;
    text-align: center;
}

.option-expiry-title .option-badge {
    margin: auto;
}

.aco-table thead th.strike-col {
    background-color: #464646;    
    color: #5de9d4;
}

.aco-table tbody td.strike-col {
    background-color: #1a1a1a;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #5de9d4;
}

.aco-table .strike-col,
.aco-table .balance-col {
    border-right: none;
}

.aco-table .na-col .na-div {
    background-color: #1a1a1a;
    height: 28px;
    line-height: 28px;
}

.aco-table thead .option-expiry-title-cell {
    text-align: center;
}

.light-mode .aco-table thead .option-expiry-title-cell {
    background-color: white;
}

.bid-price {
    color: #58fb0c;    
    font-weight: 500;
}

.ask-price {
    color: #ff0000;
    font-weight: 500;
}

.expiration-time {
    display: flex;
    flex-direction: column;
}

.expiration-from-now {
    font-size: 12px;
    font-weight: 300;
}

.size-col {
    width: 60px;
}

.bid-col { 
    width: 60px;
}

.ask-col { 
    width: 60px;
}

.size-col { 
    width: 60px;
}

.balance-col { 
    width: 80px;
}

.strike-col { 
    width: 80px;
}

.action-col {
    width: 190px;
}

.action-col .action-btn:first-child{
    margin-right: 10px;
}