.gas-disclaimer {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #464646;
    border-radius: 12px;
    padding: 8px;
}

.light-mode .gas-disclaimer {
    border: 1px solid #333333;
}

.gas-disclaimer div {
    margin-right: 8px;
    font-size: 18px;
}

.verify-modal .done-button-wrapper{
    margin-top: 26px;
    font-size: 14px;
}