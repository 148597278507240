.pool-icon-header-info {
    display: flex;    
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    flex: 1;
}

.pool-header-info {
    width: 100%;
}

.pool-card .card-header {
    justify-content: space-between;
}

.pool-name, .pool-name-detail {
    font-size: 18px;
    font-weight: 500;    
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pool-name-detail {
    justify-content: space-between;
}

.pool-name .discontinued-label {
    margin-left: 6px;
}

.pool-name img, .pool-name-detail img {
    max-height: 40px;
    max-width: 40px;
    margin-right: 12px;
}

.header-separator {
    width: 1px;
    height: 67px;
    background-color: #505050;
    margin: 0 30px;
}

.pool-icon {
    margin-right: 20px;
    height: 40px;
    width: 40px;
}

.pool-card .card-header .vault-chevron {
    margin-left: 30px
}

.pool-header-iv-info {
    text-align: center;
}

.pool-iv-value {
    color: #5de9d4;
    font-size: 18px;
    font-weight: 500;
    min-width: 50px;
}


@media (max-width:600px) {
    .header-separator {
        width: 100%;
        height: 1px;
        margin: 16px 0;
    }

    .pool-card .card-header .vault-chevron {
        margin-left: 0px;
    }

    .pool-name {
        flex-direction: column;
        align-items: flex-start;
    }

    .pool-name .discontinued-label {
        margin-left: 0;
    }

    .pool-card .input-row {
        flex-direction: column;
    }
}

.card-header.disabled {
    cursor: default;
}

.pool-card .input-row {
    justify-content: space-around;
}

.pool-card .input-column {
    max-width: 305px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pool-card .input-label {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.6px;
}

.pool-card .input-field {
    width: 100%;
}

.pool-card .input-field input {
    width: 100%;
}

.pool-position {
    margin-top: 40px;
    text-align: center;
}

.pool-position-title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    margin-bottom: 8px;
}

.pool-position tfoot td {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-align: center;
    color: #5de9d4;
}

.light-mode .pool-position .aco-table thead th.value-highlight {
    background-color: #aaaaaa;
}

.light-mode .pool-position .aco-table td.value-highlight {
    background-color: #f2f2f2;
}

.light-mode .pool-position th:first-child,
.light-mode .pool-position td:first-child {
    text-align: left;
}

.view-details-link {    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    margin: 8px;
}

.view-details-icon {
    background-image: url("/images/icon_details_w.svg");
    width: 40px;
    height: 22px;
}

.light-mode .view-details-icon {
    background-image: url("/images/icon_details_b.svg");
}

.view-details-link:hover .view-details-icon {
    background-image: url("/images/icon_details_h.svg");
}

.view-details-label {
    font-size: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.25px;
    text-align: center;
    color: #ffffff;
    margin-top: 4px;
    width: 68px;
}

.light-mode .view-details-label {
    color: #191919;
}

.view-details-link:hover .view-details-label {
    color: #5de9d4;    
}

.pool-private {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    padding: 0px 6px;
    border: 0px solid;
    border-radius: 12px;
    background-color: #676767;
    color: #ffffff;
    line-height: 2;
    align-items: center;
    word-break: keep-all;
}
.pool-private > svg {
    margin-right: 3px;
}