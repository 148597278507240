.farm {
    text-align: center;
    padding: 10px;
}

.farm-title {
    font-size: 22px;
    letter-spacing: 0.55px;
    text-align: center;
    color: #e6e6e6;
}

.farm-subtitle {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.35px;
    text-align: center;
    color: #e6e6e6;
    margin-bottom: 12px;
}

.farm-learn-more {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.35px;
    text-align: center;
    color: #5de9d4;
    margin: auto;
}

.farm-learn-more:hover {
    text-shadow: 0 2px 5px #5de9d4;
    color: #5de9d4;
    text-decoration: none;
}

.farm .rewards-chart-container {
    max-width: 1200px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
}