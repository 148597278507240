footer {
  padding: 25px 0px;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.35px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

footer a {
  color: #ffffff;
  text-decoration: underline;
  transition: opacity 200ms linear;
  -webkit-transition: opacity 200ms linear;
}

footer a:hover {
  opacity: 0.75;
  color: #ffffff;
}

footer a > svg {
  line-height: 1;
  font-size: 22px !important;
}

.footer-links, .footer-social {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.footer-links {
  justify-content: flex-start;
  line-height: 2;
}

.footer-social {
  justify-content: flex-end;
}

.footer-links > a:not(:last-child) {
  margin-right: 18px;
}

.footer-social > a:not(:last-child) {
  margin-right: 28px;
}

.footer-copyright {
  margin-right: 38px;
}

.defipulse-link {
  padding-left: 18px;
  border-left: 1px solid #696969;
  margin-left: 18px;
}

.defipulse-link img{
  height: 16px;
}

@media (max-width: 520px) {
  .defipulse-link {
    margin-top: 6px;
    margin-right: 18px;
    padding-right: 18px;
    border-left: none;
  }
}

@media (max-width: 767.98px) {
  .footer-social,
  .footer-links {
    justify-content: center;
  }
  .footer-link > a:not(:last-child) {
    margin-right: 19px; 
  }
  .footer-copyright {
    margin-right: 32px;
  }
}