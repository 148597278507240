.manage-private-pool {
    margin: auto;
    margin-top: 16px;
    border: 1px solid #464646;
    padding: 16px;
    border-radius: 12px;
}

.manage-private-pool-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    margin-bottom: 16px;
}

.dark-mode .manage-private-pool-title {
    color: #ffffff;
}

.manage-private-pool-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
}

.manage-private-pool-item-label {
    width: 100px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.6px;
}

.dark-mode .manage-private-pool-item-label {
    color: #e6e6e6;
}

.manage-private-pool-item-value {
    font-size: 12px;
    letter-spacing: 0.6px;
    margin-left: 16px;
    margin-right: 16px;
}

.dark-mode .manage-private-pool-item-value {
    color: #ffffff;
}