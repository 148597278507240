.swal2-container {
    z-index: 2000 !important;
}

.swal2-popup.swal2-modal
{
    background-color: #000000;
    padding: 14px 38px;
    width: 320px;
    border: solid 1px #464646;
    border-radius: 12px;
}

.swal2-header {
    padding: 1.25em;    
    border-radius: 8px 8px 0px 0px;
    border-bottom: solid 1px #464646;
}

.swal2-content {
    padding: 1.25em !important;
}

.swal2-popup.swal2-modal .swal2-actions {
    margin: 0 auto;
    padding-bottom: 1.25em;
    font-size: 11px;
}

.swal2-popup.swal2-modal .swal2-title {
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5de9d4;
    margin-bottom: 0;
}

.swal2-popup.swal2-modal .swal2-content div {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #bfbfbf;
}

.swal2-container.swal2-shown {
    background-color: #000000e6 !important;
}