.simple-buy-tab {
    padding: 20px 10px;
    max-width: 720px;
    width: 100%;
    margin: auto;
}

.simple-buy-tab .inputs-wrapper {
    display: flex;
    justify-content: space-between;
}

.simple-buy-tab .input-label {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    text-align: center;
}

.simple-buy-tab .input-field input {
    font-size: 16px;
    width: 100%;
    text-align: center;
}

.simple-buy-tab .input-field input::placeholder {
    color: #464646;
    opacity: 1;
}

.type-toggle {    
    border: none;
}

.type-toggle .option-badge {
    height: 100%;
    width: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.type-toggle .option-badge.active {
    cursor: default;
}

.type-toggle .option-badge.unselected {
    background: transparent;
    border: solid 1px #464646;
}

.type-toggle .option-badge.unselected:not(:hover) {
    color: #464646;
}

.type-toggle .option-badge:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.type-toggle .option-badge:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}

.simple-buy-tab select {
    width: 100%;
    height: 100%;
    background: transparent;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.8px;
    border: none;
}

.simple-buy-tab .input-column {
    max-width: unset;
    margin: 0 8px;
    text-align: center;
}

.simple-buy-tab .input-column .input-field {
    width: unset;
    min-width: 115px;
}

.simple-buy-tab .input-column.strike-column,
.simple-buy-tab .input-column.expiration-column {
    width: 175px;
    min-width: 175px;
    max-width: 175px;
}

.prices-wrapper .separator {
    border-top: solid 0.5px #464646;
    margin: 16px 0;
}

.prices-wrapper .input-label {
    height: unset;
    padding-bottom: 2px;
}

.action-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.simple-buy-tab .chart-and-prices {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
}

.simple-buy-tab .option-chart-wrapper {
    width: calc(100% - 200px);
    margin: 0 10px;
}

.simple-buy-tab .prices-wrapper {
    width: 170px;
    margin: 0 10px;
}

.price-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0.4px;
}

.price-value > div:first-child {
    text-align: right;
    min-width: 70px;
    width: 50%;
}

.price-value > div:last-child {
    font-weight: 500;
    text-align: left;
    padding-left: 6px;
    letter-spacing: 0.2px;
    width: calc(100% - 51px);
}

@media (max-width: 768px) {
    .simple-buy-tab .inputs-wrapper {
        flex-wrap: wrap;
    }

    .simple-buy-tab .inputs-wrapper .input-column,
    .simple-buy-tab .inputs-wrapper .input-column.strike-column,
    .simple-buy-tab .inputs-wrapper .input-column.expiration-column  {
        width: calc(50% - 20px);
        min-width: unset;
        max-width: unset;
    }

    .simple-buy-tab .chart-and-prices {
        flex-direction: column;
    }

    .simple-buy-tab .option-chart-wrapper {
        width: calc(100% - 20px);
    }

    .simple-buy-tab .prices-wrapper {
        width: 200px;
        margin: 10px auto;
    }
}

.configurations-column .input-value {
    padding-bottom: 8px;
}

.info-tooltip {
    max-width: 300px;
    text-align: left;
}

.create-link-wrapper {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}

.create-link-wrapper .nav-link {    
    margin: 12px 10px;
    padding: 0;
    letter-spacing: 0.3px;
    color: #5de9d4;
}

.create-link-wrapper .nav-link:hover {
    text-shadow: 0 2px 5px #5de9d4;
}

.qty-value-input svg {
    padding: 5px;
    font-size: 25px;
}