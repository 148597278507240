.exercise-action .input-column {
    margin: auto;
}

.exercise-action .input-label {
    height: unset;
    margin-bottom: 8px;
}

.summary-title {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    text-align: center;
    margin-bottom: 18px;;
}

.summary-table {
    width: 100%;
    padding: 0px;
}

.summary-table .insufficient-funds-error td,
.summary-table .insufficient-funds-error td:last-child{
    background-color: #ff0000;
}

.summary-table td {
    width: 50%;
    height: 38px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.55px;
    text-align: center;
    color: #ffffff;
}

.summary-table td span {
    display: block;
    font-size: 12px;
}

.insufficient-funds-message {
    margin-top: 12px;
    margin-bottom: 4px;
    color: white;
    font-weight: 500;
}

.exercise-action .pill-button {
    min-height: 35px;
}

.coin-symbol {
    border-radius: 2px;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.25px;
    color: #e6e6e6;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 4px;
    width: 34px;
}

.light-mode .coin-symbol {
    color: #808080;
}

.amount-to-receive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}