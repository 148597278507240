html {
  height: 100%;
  scroll-behavior: smooth;
}

body,
#root,
main {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.dark-mode {
  background-color: #000000 !important;
  color: #ffffff !important;
  transition: background-color 300ms ease-out, color 300ms ease-out;
  -webkit-transition: background-color 300ms ease-out, color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out, color 300ms ease-out;
  -o-transition: background-color 300ms ease-out, color 300ms ease-out;
}

.light-mode {
  background-color: white !important;
  color: #333333 !important;
  transition: background-color 300ms ease-out, color 300ms ease-out;
  -webkit-transition: background-color 300ms ease-out, color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out, color 300ms ease-out;
  -o-transition: background-color 300ms ease-out, color 300ms ease-out;
}

main {
  position: relative;
}

.app-content {
  min-height: calc(100vh - 63px);
  position: relative;
}

.footer-padding {
  padding: 0 0 90px 0;
}

@media (max-width: 575.98px) { 
  .footer-padding {
    padding: 0 0 130px 0;
  }
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.form-control,
.form-control:focus {
  background-color: transparent !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.nowrap {  
  white-space: nowrap;
}

.width100 {
  width: 100%;
}

.swal2-actions {
  z-index: unset !important;
}

.btn-sm {
  font-size: 15px !important;
  padding: 4px 24px !important;
}

.action-btn,
.swal2-styled.swal2-confirm.action-btn,
.cancel-btn,
.swal2-styled.swal2-cancel.cancel-btn,
.outline-btn,
.swal2-styled.swal2-confirm.outline-btn
.dark-btn
 {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 22px;
  cursor: pointer;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  outline: none !important;
  box-shadow: none !important;
  transition: background-color 300ms ease-out, color 300ms ease-out, border-color 300ms ease-out, box-shadow 300ms ease-out;
  -webkit-transition: background-color 300ms ease-out, color 300ms ease-out, border-color 300ms ease-out, box-shadow 300ms ease-out;
  -moz-transition: background-color 300ms ease-out, color 300ms ease-out, border-color 300ms ease-out, box-shadow 300ms ease-out;
  -o-transition: background-color 300ms ease-out, color 300ms ease-out, border-color 300ms ease-out, box-shadow 300ms ease-out;
}

.swal2-styled.swal2-confirm.action-btn,
.action-btn {
  color: black;
  border-color: #5de9d4;
  background-color: #5de9d4;
}

.action-btn:not(:disabled):not(.disabled):hover,
.outline-btn:not(:disabled):not(.disabled):hover,
.dark-btn:hover {
  box-shadow: 0px 0px 12px 0 #5de9d4 !important;
}

.dark-btn:hover {
  color: #5de9d4;
  border-color: #5de9d4;
}

.action-btn.disabled, .action-btn:disabled {
  background-color: #464646 !important;
  box-shadow: none !important;
  cursor: default;
}

.action-btn.medium {
  min-width: 180px;
}

.outline-btn {
  color: #5de9d4;
  border-color: #5de9d4;
  border: solid 1px;
  background-color: transparent;
}

.outline-btn img,
.dark-btn img {
  max-height: 20px;
  margin-right: 8px;
  width: 20px;
}

.dark-btn svg {
  margin-left: 8px;
}

/* .outline-btn:not(:disabled):not(.disabled):hover {
  filter: drop-shadow(0 2px 10px #5de9d4);
} */

.outline-btn.disabled, .outline-btn:disabled {
  color: #464646 !important;
  border-color: #464646;
  box-shadow: none !important;
  cursor: default;
}

.outline-btn.medium {
  min-width: 180px;
}

.dark-btn {
  cursor: pointer;
  padding: 4px 12px;
  border-radius: 22px;
  height: 44px;
  width: 100%;
  color: white;
  background-color: #1a1a1a;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.dark-btn.active {
  color: #5de9d4;
  border-color: #5de9d4;
  border: solid 1px;
}

.cancel-btn {
  color: #5de9d4 !important;
  text-decoration: none;
  background: unset !important;
}

.cancel-btn:not(:disabled):not(.disabled):hover {
  text-shadow: 0 2px 5px #5de9d4;
}

.light-mode .cancel-btn:not(:disabled):not(.disabled):hover {
  color: #33333388 !important;
}

.cancel-btn.disabled, .cancel-btn:disabled {
  background-color: #e5e5e588 !important;
  box-shadow: none !important;
}

.light-mode .cancel-btn.disabled, 
.light-mode .cancel-btn:disabled {
  background-color: #33333388 !important;
  box-shadow: none !important;
}

.modal-backdrop {
  min-width: -webkit-fill-available;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

.unshown {
  opacity: 0;
}

.base-animation {
  -o-animation-duration: 700ms;
  -moz-animation-duration: 700ms;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -o-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -o-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
  opacity: 1;
  -o-transition: opacity 0s ease-out 350ms;
  -moz-transition: opacity 0s ease-out 350ms;
  -webkit-transition: opacity 0s ease-out 350ms;
  transition: opacity 0s ease-out 350ms;
  overflow: hidden;
}

.base-animation.slide-up {
  -o-animation-name: slide-up;
  -moz-animation-name: slide-up;
  -webkit-animation-name: slide-up;
  animation-name: slide-up;
}
.base-animation.slide-down {
  -o-animation-name: slide-down;
  -moz-animation-name: slide-down;
  -webkit-animation-name: slide-down;
  animation-name: slide-down;
}
.base-animation.slide-left {
  -o-animation-name: slide-left;
  -moz-animation-name: slide-left;
  -webkit-animation-name: slide-left;
  animation-name: slide-left;
}
.base-animation.slide-right {
  -o-animation-name: slide-right;
  -moz-animation-name: slide-right;
  -webkit-animation-name: slide-right;
  animation-name: slide-right;
}

@-moz-keyframes slide-down {
  from {
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  } 
  to {
    -moz-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide-down {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  } 
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-o-keyframes slide-down {
  from {
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  } 
  to {
    -o-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes slide-down {
  from {
    transform: translateY(-100%);
  } 
  to {
    transform: translateY(0%);
  }
}
@-o-keyframes slide-up {
  from {
    -o-transform: translateY(100%);
    transform: translateY(100%);
  } 
  to {
    -o-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-moz-keyframes slide-up {
  from {
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  } 
  to {
    -moz-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  } 
  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  } 
  to {
    transform: translateY(0%);
  }
}
@-o-keyframes slide-left {
  from {
    -o-transform: translateX(100%);
    transform: translateX(100%);
  } 
  to {
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-moz-keyframes slide-left {
  from {
    -moz-transform: translateX(100%);
    transform: translateX(100%);
  } 
  to {
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-left {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  } 
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes slide-left {
  from {
    transform: translateX(100%);
  } 
  to {
    transform: translateX(0%);
  }
}
@-o-keyframes slide-right {
  from {
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  } 
  to {
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-moz-keyframes slide-right {
  from {
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
  } 
  to {
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-right {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  } 
  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes slide-right {
  from {
    transform: translateX(-100%);
  } 
  to {
    transform: translateX(0%);
  }
}

.bounce-animation {
  -o-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -o-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -o-animation-iteration-count: 5;
  -moz-animation-iteration-count: 5;
  -webkit-animation-iteration-count: 5;
  animation-iteration-count: 5;
  -o-animation-name: bounce;
  -moz-animation-name: bounce;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
@-o-keyframes bounce {
  to { 
    -o-transform: scale(1.2); 
    transform: scale(1.2); 
  }
}
@-moz-keyframes bounce {
  to { 
    -moz-transform: scale(1.2); 
    transform: scale(1.2); 
  }
}
@-webkit-keyframes bounce {
  to {
    -webkit-transform: scale(1.2); 
    transform: scale(1.2);  
  }
}
@keyframes bounce {
  to { 
    transform: scale(1.2); 
  }
}

.show-animation {
  -o-animation-duration: 1000ms;
  -moz-animation-duration: 1000ms;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -o-animation-name: show-animation;
  -moz-animation-name: show-animation;
  -webkit-animation-name: show-animation;
  animation-name: show-animation;
}
@-o-keyframes show-animation {
  from {
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
}
@-moz-keyframes show-animation {
  from {
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
}
@-webkit-keyframes show-animation {
  from {
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
}
@keyframes show-animation {
  from {
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
}

.initial-loading {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  font-size: 20px;
}

.initial-loading img {
  max-height: 60px;
}

.page-title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
  text-align: center;
  margin-bottom: 16px;
}

.page-subtitle {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 12px;
}

.aco-table {
  border: 1px solid #464646;
  border-radius: 12px;
  padding: 22px;
  border-spacing: 0;
  border-collapse: separate;
}

/* table > :first-child tr:first-child > :first-child {
  border-top-left-radius: 12px;
}

table > :first-child tr:first-child >   :last-child {
  border-top-right-radius: 12px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
} */

.aco-table thead th{
  height: 42px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #656565;
  padding: 0 15px;  
  word-break: normal;  
  text-transform: uppercase;
}

.aco-table tbody td {
  height: 48px;
  padding: 0 15px;
  text-align: center;
  word-break: normal;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.6px;
}

.aco-table td,
.aco-table th {
  border-right: 1px solid #464646;
}

.light-mode .aco-table tbody td {
  color: #333333;
  border-bottom: solid 0.2px #e6e6e6;
}

.aco-table tbody tr.clickable:hover td {
  background-color: #1a1a1a;
}

.aco-table tr td:last-child,
.aco-table tr th:last-child {
  border-right: none;
}

label {
  margin-bottom: unset;
}

* {
  outline: none !important;
}

.aco-modal .modal-content,
.steps-modal .modal-content {
  background-color: #000000;
  border: solid 1px #464646;
  border-radius: 12px;
}

.aco-modal .modal-header {
  padding: 10px 20px;
  border-bottom: 0;
  min-height: 24px;
  position: relative;
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.light-mode .aco-modal .modal-header {
  background-color: #e6e6e6;
}

.aco-modal.no-header .modal-header {
  background-color: transparent;
  padding: 0;
}

.aco-modal .modal-header .close {
  right: 5px;
  top: -2px;
  padding: 0;
  margin: 0;
  font-size: 36px;
  color: white;
  text-shadow: none;
  font-weight: 300;
  position: absolute;
  opacity: 0.9;
}

.light-mode .aco-modal .modal-header .close {
  color: #333333;
}

.aco-modal .modal-body {
  padding: 0;
}

.aco-modal .modal-dialog,
.steps-modal .modal-dialog {
  max-width: 362px;
  margin-left: auto;
  margin-right: auto;
}

.slippage-modal .modal-dialog {
  max-width: 500px;
}

.modal-backdrop.show {
  opacity: 0.9 !important;
}

.beta-alert {
  border: solid 1px;
  border-color: #464646;
  padding: 8px;
  margin: 0 auto 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  max-width: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.light-mode .beta-alert {
  border-color: #808080;
  background-color: #e6e6e6;
}

.beta-alert svg {
  margin-right: 10px;
  font-size: 24px;
}

.max-btn {
  border-radius: 2px;
  background-color: #e6e6e6;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.25px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 4px;
  width: 34px;
  cursor: pointer;
  text-align: center;    
}

.dark-mode .max-btn {
  color: #333333;
}


.input-field {
  width: 138px;
  height: 42px;
  border-bottom: solid 1px #464646;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input-field input {
  max-width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0.45px;
  padding: 0 6px;
  color: white;
}

.btn-group.pill-button-group {
  display: flex;
  margin: auto;
  width: 300px;
}

.pill-button {
  width: 50%;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5de9d4;
  border-color: #5de9d4;
  border: solid 1px;
  cursor: pointer;
  height: 42px;
  line-height: 1;
}

.pill-button svg {
  margin-left: 8px;

}

.pill-button:not(.active):hover {
  background-color: #5de9d420;
}

.pill-button:first-child {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

.pill-button:last-child {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
}

.pill-button.active {
  background-color: #5de9d4;
  color: black;
  border-color: #5de9d4;
  cursor: default !important;
}

.insufficient-funds-error .input-field {
  border-bottom: solid 1px #ff0000;
}

.insufficient-funds-error .input-field input{
  color: #ff0000;
}

.insufficient-funds-error .balance-info {
  font-weight: normal;
  color: #ff0000;
}

.accordion .card-body {
  text-align: center;
}

.accordion .card-body .action-btn {
  margin-left: auto;
  margin-right: auto;
  min-width: 160px;
}

.border-top-separator {
  border-top: 1px solid #464646 !important;
}

/* .highlight-background {
    background-color: #3b3b3b;
} */

.aco-link:hover {
  text-shadow: 0 2px 5px #5de9d4;
}

.font-mono {
  font-family: Courier, monospace;
}