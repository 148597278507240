.option-badge{
  width: 50px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.3px;
  text-align: center;
}

.put-badge {
  background-color: #501d1d;
  color: #ff0000;
}

.call-badge {
  background-color: #165417;
  color: #58fb0c;
}