.steps-modal .modal-header .close {
    padding: 0 5px;
    margin: 0 0 0 auto;
    color: rgb(196,196,196);
    text-shadow: none;
    font-weight: 300;
    font-size: 35px;
}

.steps-modal .modal-header {
    padding: 0;
    border-bottom: 0;
}

.steps-modal .modal-content {
    background-color: #000000;
    border: solid 1px #464646;
    border-radius: 6px;
}

.steps-modal-title {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 500;
    color: #5de9d4;
}

.steps-modal-subtitle {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color: #5de9d4;
}

.steps-modal-subtitle-2 {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    border-top: 1px solid #464646;
    padding: 2rem;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: #bfbfbf;
    margin-bottom: 0;
}

.steps-modal .steps {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: auto;
    padding-top: 32px;
    width: 100%;
}

.steps-modal .start-dot {
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    background-color: #46f217;
}

.steps-modal .step-item {
    align-items: center;
    display: flex;
    flex-grow: 1;
}

.steps-modal .step-container {
    flex-grow: 1;
    margin: 0 3px;
    position: relative;
}

.steps-modal .step-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    position: absolute;
    text-align: center;
    top: -32px;
    white-space: nowrap;
    width: 100%;
    color: #464646;
}

.steps-modal .step-title.step-title-active {
    color: white;
}

.light-mode .steps-modal .step-title.step-title-active {
    color: #333333;
}

.steps-modal .step-line {
    height: 2px;
    margin: 0;
    position: relative;
    background-color: #3e3e3e;
}

.steps-modal .step-line-progress {
    background: #46f217;
    height: 3px;
    left: 0;
    margin: 0;
    position: absolute;
}

.steps-modal .step-dot {
    color: #3e3e3e;
    font-size: 16px;
}

.steps-modal .step-dot.step-dot-done {
    color: #46f217;
}

.steps-modal .steps-modal-icon {
    margin: auto;
    text-align: center;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.done-button-wrapper {
    margin-bottom: 26px;
}