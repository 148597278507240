.otc-orders-modal.aco-modal .modal-dialog {
    width: 600px;
    max-width: 100%;
}

.order-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.order-summary .right-arrow {
    margin: 0 10px;
    line-height: 1;
}

.otc-order {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #464646;
}

.otc-order:last-child {
    border-bottom: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.otc-order:hover {
    background-color: #1a1a1a;
}

.light-mode .otc-order:hover {
    background-color: #e6e6e680;
}

.no-orders-message {
    text-align: center;
    padding: 20px;
    margin: auto;
}