.input-row,.balance-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.balance-row {
    margin-top: 8px;
    margin-left: 4px;
}

.input-column {
    max-width:  138px;
}

.input-label {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.balance-info {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.55px;
    color: #e6e6e6;
}

.light-mode .balance-info {
    color: #333333;
}


.equal-symbol {
    margin-top: 40px;
    font-size: 20px;
}

.swap-link {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #139bf2;
    text-decoration: underline;
    cursor: pointer;
}

.write-step3 .swap-link {
    width: 115px;
    display: block;
}