.accordion .card {
    margin: 20px 0;
    border-radius: 12px !important;
    border: solid 1px #464646 !important;
}

.accordion .card .card-header {
    background-color: #f7f7f7;    
    display: flex;
    flex-direction: row;    
    align-items: center;
}


@media(max-width:600px) {
    .accordion .card .card-header {
        flex-direction: column;
    }
}

.dark-mode .card .card-header {
    background-color: #000000;
}

.dark-mode .accordion .card {
    background-color: #000000;
}

.vault-name {
    width: 230px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.discontinued-label {
    color: #ff0000;
    font-size: 13px;
}

.vault-name img {
    max-height: 40px;
    max-width: 40px;
    margin-right: 12px;
}

.vault-details { 
    max-width: calc(100% - 230px);
    display: flex;
    flex-direction: row;    
    justify-content: space-evenly;
    flex: 1;
    align-items: center;
    min-width: 300px;
}

@media (max-width:600px) {
    .vault-details { 
        margin-bottom: 8px;
    }
}

.header-separator {
    width: 1px;
    height: 67px;
    background-color: #464646;
}


@media (max-width:600px) {
    .header-separator {
        width: 100%;
        height: 1px;
        margin: 16px 0;
    }
}

.vault-apy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.9px;
    color: #5de9d4;
    white-space: nowrap;
    margin: 8px;
}

.vault-apy-value {
    font-size: 17.5px;
    font-weight: 600;
}

@media(max-width:600px) {
    .vault-apy-value {
        font-size: 14px;
    }
}

.vault-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.7px;
    
    max-width: 80%;
}

.vault-current-option {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.vault-current-option > span {
    margin-right: 4px;
}

@media(max-width:600px) {
    .vault-current-option {
        flex-direction: column;
    }

    .vault-current-option > span {
        margin-right: 0;
    }   

    .vault-card .input-row {
        flex-direction: column;
    }
}

.right-arrow {    
    font-size: 28px;
}

.vault-chevron {
    transform: rotate(-180deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.card-header.collapsed .vault-chevron  {
    transform: rotate(0deg);
}

.card-header.disabled {
    cursor: default;
}

.vault-card .input-row {
    justify-content: space-around;
}

.vault-card .input-column {
    max-width: 305px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vault-card .input-label {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.6px;
}

.vault-card .input-field {
    width: 100%;
}

.vault-card .input-field input {
    width: 100%;
}

.vault-card .action-btn,
.vault-card .outline-btn {
    margin-top: 12px;
}

.vault-position {
    margin-top: 40px;
    text-align: center;
}

.vault-position-title {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    margin-bottom: 8px;
}

.vault-position tfoot td {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-align: center;
    color: #5de9d4;
    padding: 15px 15px 0;
    border-right: none;
}

.light-mode .vault-position .aco-table thead th.value-highlight {
    background-color: #aaaaaa;
}

.light-mode .vault-position .aco-table td.value-highlight {
    background-color: #f2f2f2;
}

.light-mode .vault-position th:first-child,
.light-mode .vault-position td:first-child {
    text-align: left;
}

.accordion .card-body {
    text-align: center;
}

.accordion .card-body .action-btn {
    margin-left: auto;
    margin-right: auto;
}