.privacy-title {
    margin-top: 20px;
}

.force-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.privacy-container h3 {
    font-size: 22px;
}