.pool-summary {
    margin-bottom: 14px;
    font-size: 13px;
}

.pool-warning {
    padding-top: 20px;
    color: #5de9d4;
}

.deposit-modal .form-group.form-check {
    font-size:13px;
}

.deposit-modal input[type=checkbox]
{
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  transform: scale(1.3);
}

.slippage-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.slippage-value svg {
    font-size: 18px;
    margin-left: 6px;
}