.option-types {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.option-type-card {
    width: 240px;
    border-radius: 4px;
    border: solid 0.5px #464646;
    margin: 8px 16px 16px;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    position: relative;
}

.option-type-card:hover {
    background-color: #1a1a1a;
}

.option-type-card.call:hover {
    border: solid 0.5px #58fb0c;    
}

.option-type-card.put:hover {
    border: solid 0.5px #ff0000;
}

.option-type-description {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.3px;
    color: #a6a6a6;
    margin-bottom: 32px;
}

.learn-more {
    font-size: 14px;
    position: absolute;
    right: 4px;
    top: 2px;
    color: #a6a6a6;
}

.learn-more:hover {
    color: white;
}

.option-type-card .action-btn {
    font-size: 12px !important;
}

.option-type-tooltip {
    max-width: 300px;
    text-align: left;
}