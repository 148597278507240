.chart-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    position: relative;
}

#option-chartjs-tooltip {
    background-color: black;
}

.light-mode #option-chartjs-tooltip {
    background-color: #e2e2e2;
}