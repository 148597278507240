.rewards-modal .modal-dialog {
    max-width: 480px;
}

.rewards-modal .modal-body {
    padding-bottom: 72px;
}


.rewards-title {
    font-size: 22px;
    letter-spacing: 0.55px;
    text-align: center;
    color: #e6e6e6;
}

.reward-cards {
    display: flex;
    justify-content: center;
    margin-top: 26px;    
}



@media(max-width: 760px) {
    .reward-cards {
        flex-direction: column;
        align-items: center;
    }
}

.reward-card {
    width: 376px;
    max-width: calc(100% - 20px);
    border-radius: 12px;
    border-radius: 12px;
    border: solid 1px #464646;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 12px;
}

.reward-card-content {
    padding: 30px 20px;
}

.reward-card-title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #5de9d4;
}

.reward-card-balance {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #5de9d4;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 36px;
}

.reward-card-balance-mb {
    margin-bottom: 34px;
}

.reward-card-link {
    line-height: 20px;
    margin-bottom: 14px;
    text-align: center;
    font-size: 13px;
    text-decoration: underline;
    color: #5de9d4;
    cursor: pointer;
}

.reward-card-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reward-card-input .input-field {
    width: calc(100% - 140px);
}

.reward-card-input .action-btn {
    width: 130px;
}

.reward-card-percentage-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;;
}

.reward-card-percentage-button {
    padding: 4px 12px;
    border-radius: 22px;
    font-size: 14px;
    width: 72px;
}

.reward-action {
    border-top: 1px solid #464646;
    padding: 32px;
    text-align: center;
}

.unclaimed-rewards-title {
    font-size: 22px;
    letter-spacing: 0.55px;
    text-align: center;
    color: #e6e6e6;
    margin-top: 30px;
}

.reward-card-balance-value {
    font-size: 16px;
    margin-left: 4px;
}