.trade-menu {
    background-color: #141414;
    width: 300px;
    height: calc(100vh - 63px);
    display: flex;
    flex-direction: row;
}

.trade-menu-content-wrapper {
    box-shadow: inset -14.1px -16.9px 38px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.trade-menu-collapse {
    display: none;
    z-index: -1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: white;
    cursor: pointer;
    height: 100%;
    width: 36px;
}

.light-mode .trade-menu{
    background-color: #dadada;
}

.trade-menu-pair-balance-info {
    padding: 0px 15px 8px;
}

.trade-menu-balance-title {
    font-size: 17.5px;
    font-weight: 600;
    color: #5de9d4;
    text-align: left;
    padding: 10px 0;    
}

.trade-menu-manage {
    padding-bottom: 8px;
}

.trade-menu-manage .trade-menu-balance-title {
    padding: 10px 15px;
}

.trade-menu-manage-link {
    font-size: 14px;
    font-weight: 300;
    padding: 6px 21px;
    cursor: pointer;
    color: white;
    display: block;
    text-decoration: none;
    background-color: transparent;
}

.trade-menu-manage-link:hover,
.trade-menu-manage-link.active  {    
    background-color: #1a1a1a;
}

.pair-balance-items {
    display: flex;
    flex-direction: column;
    border: 1px solid #464646;
    border-radius: 12px;
}

.pair-balance-item {
    display: flex;
    flex-direction: row;
    padding: 12px;
}

.pair-balance-item {
    border-bottom: 1px solid #464646;
}

.pair-balance-item:last-child {
    border-bottom: none;
}

.trade-menu-pair-symbol,
.trade-menu-pair-balance {    
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.3px;
}

.trade-menu-pair-symbol {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 300;    
    min-width: 50px;
}

.trade-menu-pair-balance {
    text-align: right;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 500;
    max-width: calc(100% - 50px);
}

.trade-menu-pair-title {
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #5de9d4;
    display: flex;
    align-items: center;
}

.trade-menu-pair-title .asset-icon {
    height: 34px;
    margin-right: 8px;
}

.trade-menu .option-badge {
    margin: 0 15px 8px;
}

.option-expirations-wrapper {
    margin-bottom: 15px;
}

.option-expiration-item {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    text-align: left;
    color: #7f7f7f;
    display: flex;
    flex-direction: row;
    padding: 8px 15px;
    position: relative;
}

.light-mode .option-expiration-item {
    color: #333333;
}

.option-expiration-item {
    cursor: pointer;
}

.option-expiration-item:hover,
.option-expiration-item.active {
    color: #fff;
    background-color: #1a1a1a;
}

.light-mode .option-expiration-item:hover,
.light-mode .option-expiration-item.active {
    color: #333333;
    background-color: white;
}

.trade-menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100% - 57px);
    overflow: auto;
}

.trade-menu-content > div {
    width: 100%;
}

.trade-menu-footer {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    padding-bottom: 14px;
    height: 57px;
}

.trade-menu-footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 0px 15px;
}

.trade-menu-footer a {
    font-size: 10px;
    color: #e6e6e6;
    text-decoration: underline;
    transition: opacity 200ms linear;
    -webkit-transition: opacity 200ms linear;
    letter-spacing: 0.4px;
    cursor: pointer;
}

.trade-menu-footer a:hover {
    color: #e6e6e6;
    opacity: 0.75;
}

.light-mode .trade-menu-footer a,
.light-mode .trade-menu-footer a:hover {
    color: #333333;
}

.trade-menu-footer-media {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    color: #676767;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 12px;
    padding: 0px 15px;
}

.trade-menu-footer-media > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    line-height: 1;
}

.trade-menu-footer-media svg {
    font-size: 15px;
    margin-left: 17px;
}

.trade-menu-content .pair-dropdown,
.trade-menu-content .dropdown-toggle,
.trade-menu-content .pair-nav-container {
    width: 100%;
}

.trade-menu-content .pair-dropdown {
    padding: 8px;
    border-bottom: solid 1px #464646;
}

.trade-menu-content .pair-dropdown .pair-img-symbol > div {
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    width: 100%;
}

.trade-menu-content .pair-price {
    color: #5de9d4;
}

@media (max-width: 991px) {
    .trade-menu {
        width: 100%;
    }
    .trade-menu-collapse {
        display: flex;
        z-index: auto;
    }
}