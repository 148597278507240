.trade-page {
    height: calc(100vh - 63px);
    display: flex;
    flex-direction: row;
}

.advanced-content-wrapper {
    height: calc(100vh - 63px) !important;
    width: calc(100% - 300px);
}

.advanced-content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

@media (max-width: 991px) {
    .advanced-content-wrapper {
        width: calc(100% - 36px);
    }
}