.navbar-aco { 
  background: #000000;
  border-bottom: solid 1px #464646;
}

.navbar.navbar-aco { 
  padding-top: 0;
  padding-bottom: 0;
  z-index: 1;
}

.navbar-items {
  align-self: stretch;
}

.light-mode .navbar-aco {
  background: white;
  border-bottom: solid 1px #808080;
}

.navbar-aco .container-fluid {
  padding: 0
}

.nav-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.nav-logo .aco-logo{
  height: 32px;
  width: auto;
}

.nav-logo .by-auctus-link {
  margin-top: 4px;
  font-size: 0;
}

.nav-logo .by-auctus-logo {
  margin-top: 0px;
  height: 9px;
  width: auto;
}

.logo-link {
  cursor: pointer;
  margin-right: 80px;
}

@media (max-width: 1200px) {
  .logo-link {
    margin-right: 20px;
  }
}


.btn-nav {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-align: center;
  color: white;
  margin-right: 10px;
}

.link-nav {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.7px;
  text-align: left;
  color: #ffffff !important;
  cursor: pointer;
}

.light-mode .link-nav{
  color: #858585 !important;
}

.link-nav.active {
  color: #5de9d4 !important;
}

.link-nav:hover {  
  color: #5de9d4 !important;
  text-decoration: none;
}

.link-title {
  text-align: left;
}

.link-subtitle {
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 1;
}

.user-nav {
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 2;
  margin-right: 5px;
}

.user-nav-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 10px;
}

.user-nav-wrap > img {
  font-size: 21px;
  line-height: 1;
  margin-right: 10px;
  max-height: 22px;
  max-width: 32px;
}

.user-nav-wrap > div {
  display: flex;
  flex-direction: column;
}

.wallet-address {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.35px;
  text-align: left;
}

.light-mode .navbar-dark .navbar-nav .nav-link {
  color: #333333 !important;
}

.light-mode .navbar-dark .navbar-nav .nav-link:hover,
.light-mode .navbar-dark .navbar-nav .nav-link:focus {
  color: #333333AA !important;
}

.connected-label,
.invalid-network-label {
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.3px;
  text-align: left;
}

.connected-label {
  color: #58fb0c;
}

.invalid-network-label {
  color: #ff0000;
}

.user-img-nav {
  height: 22px;
  width: 22px;
  margin-right: 6px;
  border-radius: 50%;
}

.user-nav-container,
.pair-nav-container {
  display: inline-block;
  min-width: max-content;
}

li.dropdown {
  padding: 0 10px;
  max-height: 45px;
}

.nav-item.dropdown.metamask {
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: white !important;  
  display: flex;
  flex-direction: row;
}

.nav-item.dropdown.slippage-config {
  align-items: center;
  justify-content: center;
  display: flex;
}

.nav-item.dropdown.slippage-config .dropdown-menu {
  width: 350px;
  padding: 15px;
}

.nav-item.dropdown.slippage-config .outline-btn,
.nav-item.dropdown.slippage-config .action-btn {
  font-size: 14px;
}

.nav-item.dropdown.slippage-config .steps-modal-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 16px;
}

.nav-item.dropdown.slippage-config .slippage-options .input-field {
  width: 72px;
}

.close-slippage-dropdown {
  position: absolute;
  right: 15px;
  color: rgb(196,196,196);
  text-shadow: none;
  font-size: 14px;
  z-index: 1;
  cursor: pointer;
}

.slippage-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: rgba(152,151,151, 0) !important;
  color: #dedede !important;  
}

.dropdown-toggle::after {
  vertical-align: 0.5em !important;
}

.dropdown-menu {
  background-color: #1a1a1a !important;
  right: 0;
  left: unset !important;
}

.dropdown-item {
  color: white !important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #5de9d4 !important;
  background-color: unset !important;
}

.navbar-items .nav-item {
  text-align: center;
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar .navbar-collapse {
  min-height: 62px;
}

@media (max-width: 991px) {
  .navbar-collapse .navbar-items .nav-item {
    padding: 6px 0;
    align-items: flex-start;
  }

  .navbar-collapse .nav-separator {
    display: none;
  }

  .navbar-items .options-nav-item {
    margin-bottom: 0;
  }

  li.dropdown {
    max-height: unset;
  }
}

.advanced.navbar-items .nav-item {
  align-items: center;
}

.advanced .nav-item {
  position: relative;
}

.options-nav-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.options-nav-item .nav-chevron {
  margin-left: 8px;
}

.nav-separator {
  width: 1px;
  background-color: #464646;
  margin: 8px;  
  align-self: stretch;
}

.advanced.navbar-items .nav-item.active::after {
  content: "";
  position: absolute;
  bottom: 0px;
  height: 4px;
  background-color: #5de9d4;
  width: 100%;
  left: 0;
}

.nav-item.dropdown .dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-mode {
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #858585;
  cursor: pointer;
  margin-left: 22px;
  margin-right: 25px;
}

.app-mode.active {
  color: #5de9d4;
  cursor: default;
  margin-right: 0px;
}

.app-mode.active::after {
  content: "";
  border-left: 1px solid #858585;
  margin-left: 22px;
}

.app-mode > svg {
  line-height: 1;
  margin-left: 7px;
}

.nav-modes {
  flex-direction: row !important;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.advanced-tooltip {
  display: flex;
  flex-direction: column;
  max-width: 160px;
  z-index: 1;
  padding: 6px 16px;
  background-color: #000000;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 0.3px;
  position: absolute;
  border: 1px solid #464646;
  color: #ffffff;
  top: 50px;
  margin-left: -55px;
}

.advanced-tooltip::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  top: -6px;
  left: 50%;
  margin-left: -8px;
  border-bottom-color: #464646;
  border-bottom-style: solid;
  border-bottom-width: 6px;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

.advanced-tooltip .action-btn {
  padding: 0;
  width: 65px;
  text-transform: none;
  margin-top: 6px;
}

.layout-mode {
  padding: 6px 0;
  margin-right: 8px;
}

.layout-mode label {
  font-size: 11.5px;
  font-weight: 500;  
  line-height: 24px;
  letter-spacing: 0.58px;
  color: #9a9a9a;
  width: 70px;
  cursor: pointer;
}

.subnav-content {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
  top: 62px;
  box-shadow: 16px 12px 20px 0 rgba(0, 0, 0, 0.28);
  background-color: #000000e6;
  cursor: default;
}

.light-mode .subnav-content{
  background-color: #f2f2f2;
}

.subnav-content a {
  float: left;
  color: white;
  text-decoration: none !important;
}

.light-mode .subnav-content a {
  color: #656565;
}

.subnav-link .link-nav {
  color: #5de9d4 !important;
}

.subnav-content a:hover .subnav-link-title,
.subnav-link .link-nav:hover {
  text-shadow: 0 2px 5px #5de9d4;
}

.subnav-content a:hover .subnav-link-description {
  color: #e2e2e2;
}

.light-mode .subnav-content a:hover .subnav-link-description {
  color: #333333;
}

@media(min-width:992px) {
  .link-nav:hover .subnav-content {
    display: block;
  }
}

.navbar .subnav-content .container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar .subnav-content .container .nav-separator{
  margin: 8px 16px;
}

.subnav-content .container .subnav-link {
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.subnav-content .container .subnav-link .subnav-link-title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.7px;
  text-align: left;
  color: #5de9d4;
}

.subnav-content .container .subnav-link .subnav-link-description {
  max-width: 160px;
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.3px;
  text-align: left;
}

.subnav-link .nav-item.link-nav {
  padding: 4px;
}

@media(min-width:992px) {  
  .subnav-content-mobile {
    display: none;
  }
}

.navbar .subnav-content-mobile .container {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.subnav-content-mobile a {
  float: left;
  color: white;
  text-decoration: none !important;
}

.light-mode .subnav-content-mobile a {
  color: #656565;
}

.network-btn {
  max-width: max-content;
  margin-right: 18px;
  border-radius: 22px;
  border: solid 1px #464646;
  background-color: transparent;
}

@media(max-width:991px){  
  .options-nav-item .nav-chevron {
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .options-nav-item.options-expanded .nav-chevron {
    transform: rotate(-180deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .network-btn {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
  }
  .nav-item.dropdown.metamask {
    flex-direction: column;
  }
}

.connect-nav {
  display: flex;
}

.dropdown-divider {  
  border-top: 1px solid #464646 !important;
}

.pools-link .link-title {
  width: 88px;
  position: relative;
}

.earn-badge {
  border-radius: 5px;
  background-color: #5de9d4;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.35px;
  text-align: center;
  color: #242424;
  position: absolute;    
  top: -11px;
  right: 0px;
  padding: 0px 5px;
}