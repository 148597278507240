.confirm-card {
    margin: auto;
    width: 360px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.55px;
    text-align: center;
    color: #e6e6e6;
}

.confirm-card-header {
    border-bottom: 1px solid #464646 !important;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0.6px;
    text-align: center;
    color: #5de9d4;
    padding: 16px;
}

.modal-body .confirm-card-header,
.modal-body .confirm-card {
    border-radius: 0;
}

.light-mode .confirm-card-header {
    background-color: #e6e6e6;
}

.confirm-card-body {    
    padding: 20px;
}

.confirm-card-actions .aco-button {
    width: 120px;
    margin: 0px 8px 20px;
}

.card-separator {
    height: 1px;
    width: 100%;
    background: #464646;
    margin: 16px 0;
}