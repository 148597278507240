.simple-box {
    max-width: 880px;  
    margin: 20px auto;
    border-radius: 4px;
}

.simple-box .nav-tabs {
    background-color: #000000;
    border-bottom: 0;    
    justify-content: center;
}

.light-mode .simple-box .nav-tabs {
    background-color: #e6e6e6;
}

.simple-box #simpleTabs .nav-item {
    margin-bottom: 0;
    max-width: 33.3%;
    position: relative;
}

.simple-box #simpleTabs .nav-item:before {
    height: 100%;
    width: 1px;
    background: #1a1a1a;
    display: block;
    content: " ";
    position: absolute;
}

.simple-box .nav-tabs .nav-item:first-child:before {
    display: none !important;
}

.simple-box .nav-tabs .nav-link {
    border: 0;
    border-bottom: 4px solid;
    border-bottom-color: transparent;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.45px;    
    color: #676767;
    position: relative;
    width: 100%;
    height: 65px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 0;    
}

.light-mode .simple-box .nav-tabs .nav-link {
    color: #333333;
}

.simple-box .nav-tabs .nav-link:hover,
.simple-box .nav-tabs .nav-link:focus {
    border-bottom-color: #5de9d4;
}

.simple-box .nav-tabs .nav-link.active {
    background-color: transparent;
    border-bottom-color: #5de9d4;
    font-weight: 500;
    color: #5de9d4;
    background-color: #1a1a1a;
    
}

.pair-and-mode-wrapper {
    display: flex;
    max-width: 880px;
    margin: auto;    
    position: relative;
    justify-content: center;
}

.simple-page .nav-modes {
    padding: 0;
}

.pair-and-mode-wrapper .pair-dropdown-wrapper .nav-link {
    padding: 0;
}


@media(max-width:570px) {
    .simple-box .nav-tabs .nav-link {
        font-size: 13px;
    }

    .pair-and-mode-wrapper {
        flex-direction: column-reverse;
    }

    .pair-dropdown-wrapper {
        padding-inline-start: 0px;
    }

    .simple-page .nav-modes {
        margin-right: auto;
    }
}