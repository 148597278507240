.aco-modal.update-selling-options-modal .modal-dialog {
    width: 600px;
    max-width: 100%;
}

.update-selling-options-modal .confirm-card {
    width: auto;
}

.aco-modal.update-selling-options-modal .input-field.input-field-sm {
    width: 80px;
}

.aco-modal.update-selling-options-modal .input-row{
    justify-content: center;
}

.label-input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.input-row > div {
    margin: 4px;
}

.label-input-column .form-group.form-check {
    margin-bottom: 4px;
}

.no-max-input-row > div:not(.label-input-column) {
    margin-top: 26px;
}

.no-max-checked {
    color: #4a4a4a;
}

.no-max-checked .input-field {
    border-color: #383838;
}

.no-max-checked .coin-symbol {
    color: #4a4a4a;
}

.no-max-input .form-check-label {
    margin-top: 3px;
}