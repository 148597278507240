.otc-title {
    font-size: 26px;
    position: absolute;
    text-align: center;
    height: 54px;
    line-height: 54px;    
}

.orders-count-badge {
    border-radius: 5px;
    background-color: #5de9d4;
    color: black;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.35px;
    text-align: center;
    color: white;
    position: absolute;
    top: 0px;
    right: 4px;
    padding: 0px 4px;
    min-width: 24px;
}

.orders-btn {    
    color: #d2d2d2;
    border-color: #d2d2d2;
    padding: 2px 16px;
    border: 1px solid;
    border-radius: 22px;
}

.light-mode .orders-btn { 
    color: #808080;
    border-color: #808080;
}

.otc-content .pair-and-mode-wrapper {
    justify-content: center;
}

@media(min-width: 881px) {
    .otc-content .pair-and-mode-wrapper .nav-modes {
        right: 16px;
    }

    .orders-count-badge {
        right: -12px;
    }
}