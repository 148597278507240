.pools-wrapper {
    max-width: 760px;
    margin: auto;
    padding: 16px;
}

.pooled-liquidity {
    border-radius: 12px;
    margin: auto;
    background-color: #151515;
    margin-bottom: 16px;
}

.pooled-liquidity {
    background-color: transparent;
}

.pooled-liquidity-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pooled-liquidity-title-row .create-pool-btn {
    margin-bottom: 16px;
}

.pooled-liquidity-title {
    margin-bottom: 16px;
    font-weight: bold;
}

.alert-message {
    margin-top: 16px;
    color: #5de9d4;
}